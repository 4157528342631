import './styles.scss';

const Versus = function() {
  return (
    <div className="versus">
      или
    </div>
  );
}

export default Versus;